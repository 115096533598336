import React from 'react';
import { Box, Flex, Image } from '@lce/slice_v2';

interface IFundraiserCreate {
  image: string;
  testId: string;
}

export const FundraiserCreate: React.FC<IFundraiserCreate> = ({ children, image, testId }) => (
  <Flex data-testid={ testId } sx={ { flexDirection: 'row', gap: '10px' } }>
    <Box sx={ { display: [ 'none', 'none', 'block' ], flexBasis: '40%', minHeight: '100vh' } }>
      <Image
        alt="LC Fundraising Logo"
        src={ image }
        sx={ { position: 'sticky', top: '50%', transform: 'translateY(-50%)' } }
        title="LC Fundraising"
      />
    </Box>
    {children}
  </Flex>
);
