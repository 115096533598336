import { useMemo } from 'react';
import { format } from 'date-fns';

export const useSafeDateFormat = (date, formatPattern = 'MM-dd-yyyy', fallbackValue = 'unknown') => useMemo(() => {
  if (!date) {
    return fallbackValue;
  }
  try {
    return format(new Date(date), formatPattern);
  } catch (error) {
    return fallbackValue;
  }
}, [ date, fallbackValue, formatPattern ]);
