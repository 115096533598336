import { useMemo } from 'react';
import { isBefore } from 'date-fns';

import { FundraiserListItem } from '../types';
import { FundraiserStatus } from '../types/fundraiser';

export const useIsUpcomingFundraiser =
(fundraiser: FundraiserListItem | undefined): boolean => useMemo(() => {
  if (fundraiser) {
    return isBefore(new Date(), new Date(fundraiser.StartDate)) ||
    fundraiser.FundraiserStatus === FundraiserStatus.Pending;
  } else {
    return false;
  }
},
[ fundraiser ]);
