/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Button, Box, Form, Flex, Text, Container, Spinner, Option,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import { useOktaAuth } from '@okta/okta-react';
import {
  addDays, addYears, format, isToday, subDays, startOfToday,
} from 'date-fns';
import _ from 'lodash';

import FundraiserDetailsSummary from './FundraiserDetailsSummary';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { Step, Mode } from 'ui/common';
import {
  useFetchUserGroups, Group, FundraiserType,
} from 'features/fundraiser';
import { useFetchGroupTypes } from 'features/fundraiser/hooks';
import { useFetchStates, useUserInfo } from 'features/common/hooks';
import { useFetchGroup } from 'features/fundraiser/hooks/useFetchGroup';
import { useVerifySalesAgent } from 'features/salesAgent';
import { FundraiserBrochure, FundraiserFormStepState } from 'features/fundraiser/types/fundraiser';

export interface IFundraiserDetailsProps {
  onEdit: () => void;
  onNext: (info: Partial<FundraiserBrochure>) => void;
  info: FundraiserBrochure | undefined;
  mode: Mode;
  fundraiserType: FundraiserType;
}

const FundraiserDetails: React.FC<IFundraiserDetailsProps> = ({
  onEdit, onNext, mode, info, fundraiserType,
}) => {
  const [ t ] = useTranslation();
  const { authState } = useOktaAuth();

  const tenDaysFromToday = addDays(startOfToday(), 10);
  const defaultStartDate = info?.startDate ?? tenDaysFromToday;
  const defaultStartDateFormatted = format(new Date(defaultStartDate), 'yyyy-MM-dd');

  const [ selectedGroupId, setSelectedGroupId ] = useState<string>(info?.organizationGroupId?.toString() || '0');
  const [ showGroupList, setShowGroupList ] = useState<boolean>(false);
  const [ dollarGoal, setDollarGoal ] = useState<number>(info?.profitGoal || 0);
  const [ numberOfSellers, setNumberOfSellers ] = useState<number>(info?.sellerCount || 0);
  const [ calculatedKitGoal, setCalculatedKitGoal ] = useState<number>(info?.sellerKitGoals || 0);
  const [ existingGroup, setExistingGroup ] = useState<Group>();
  const [ canEditSalesCode, setCanEditSalesCode ] = useState<boolean>(false);
  const [ canFetchSalesAgent, setCanFetchSalesAgent ] = useState<boolean>(false);
  const [ earliestPossibleStartDate, setEarliestPossibleStartDate ] = useState<Date>(tenDaysFromToday);
  const [ salesAgentHasErrors, setSalesAgentHasErrors ] = useState<boolean>(false);
  const [ salesCode, setSalesCode ] = useState<string>('');
  const [ isGroupTypeIdDirty, setGroupTypeIdDirty ] = useState<boolean>(false);

  const { data: groups, isLoading: isGroupLoading } = useFetchUserGroups();
  const { data: groupTypes, isLoading: isGroupTypeLoading } = useFetchGroupTypes();
  const { data: groupDetail } = useFetchGroup(selectedGroupId);
  const { data: states, isFetched: isStatesFetched } = useFetchStates(1);
  const { data: userInfo } = useUserInfo(authState.isAuthenticated);
  const {
    data: salesAgent, status: salesAgentStatus, isSuccess: isSuccessForSalesAgent, isLoading: isSalesAgentLoading,
  } =
    useVerifySalesAgent(canFetchSalesAgent, salesCode);

  const defaultGroupTypeId = groupDetail?.GroupTypeId;
  const defaultState = groupDetail?.GroupAddress?.StateProvinceIso ?? info?.organizationState;

  const hasOutOfRangeGroupTypeId = useMemo<boolean>(() => {
    if (isGroupTypeIdDirty) {
      return false;
    }

    if (Array.isArray(groupTypes)) {
      const highestGroupId = _.chain(groupTypes)
        .orderBy('Id')
        .last()
        .get('Id')
        .value();
      const currentGroupTypeId = groupDetail?.GroupTypeId ?? 0;

      return highestGroupId < currentGroupTypeId;
    }

    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ groupDetail?.GroupTypeId, groupTypes, isGroupTypeIdDirty ]);

  useEffect(() => {
    if (selectedGroupId === '0') {
      setExistingGroup(undefined);
    } else if (groupDetail) {
      setExistingGroup(groupDetail);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ groupDetail, selectedGroupId ]);

  useEffect(() => {
    if (salesAgentStatus === 'success' || salesAgentStatus === 'error') {
      setCanFetchSalesAgent(false);

      if (salesAgent && !salesAgent.SalesCode) {
        setSalesAgentHasErrors(true);
      } else {
        setSalesAgentHasErrors(false);
      }
    }
  }, [ salesAgent, salesAgentStatus ]);

  useEffect(() => {
    if (userInfo && userInfo.Roles) {
      const { Roles } = userInfo;
      // ISC Users can edit sales code during creation
      if (Roles.includes(FundraiserDefaults.ISCRoleName) ||
          Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
          Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setCanEditSalesCode(true);
      }

      // Supers and Sales Agents can set start date as today
      if (Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
          Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setEarliestPossibleStartDate(startOfToday());
      }

      // Only get list of groups if one of these roles
      if (Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
          Roles.includes(FundraiserDefaults.ChairpersonRoleName) ||
          Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setShowGroupList(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userInfo ]);

  const validationSchema = useMemo(() => {
    const threeYearsFromToday = addYears(startOfToday(), 3);
    const startDayMin = subDays(earliestPossibleStartDate, 1);
    const startDayMinText = t('fundraiser.validation.StartDateMin', {
      context: isToday(earliestPossibleStartDate) ? '' : 'ten',
    });

    return Yup.object({
      organizationGroupId: Yup.number(),
      name: Yup.string()
        .required(t('fundraiser.validation.OrgNameRequired'))
        .matches(/^[a-zA-Z0-9\s']+$/,
          t('fundraiser.validation.OrgNameMatches'))
        .max(255, t('fundraiser.validation.OrgNameLength')),
      organizationGroupTypeId: Yup.number().min(1, t('fundraiser.validation.GroupTypeIdRequired')),
      startDate: Yup.date()
        .required(t('fundraiser.validation.StartDateRequired'))
        .min(startDayMin, startDayMinText)
        .max(threeYearsFromToday, 'Start date cannot be more than 3 years from today')
        .typeError(t('fundraiser.validation.StartDateRequired')),
      profitGoal: Yup.number()
        .required(t('fundraiser.validation.DollarGoalRequired'))
        .nullable()
        .min(0.01, t('fundraiser.validation.DollarGoalMin'))
        .max(1000000, t('fundraiser.validation.DollarGoalMax'))
        .typeError(t('fundraiser.validation.DollarGoalInvalid')),
      sellerCount: Yup.number()
        .required(t('fundraiser.validation.EstimatedSellersRequired'))
        .nullable()
        .integer(t('fundraiser.validation.EstimatedSellersNumber'))
        .min(1, t('fundraiser.validation.EstimatedSellersMin'))
        .max(99999, t('fundraiser.validation.EstimatedSellersMax'))
        .typeError(t('fundraiser.validation.EstimatedSellersInvalid')),
      brochuresRequestCount: Yup.number()
        .required(t('fundraiser.validation.BrochureCountRequired'))
        .integer(t('fundraiser.validation.BrochureCountNumber'))
        .min(0, t('fundraiser.validation.BrochureCountMin'))
        .max(99999, t('fundraiser.validation.BrochureCountMax'))
        .typeError(t('fundraiser.validation.BrochureCountInvalid')),
      couponCode: Yup.string().trim(),
      organizationStreetAddress: Yup.string().required(t('fundraiser.validation.AddressRequired')),
      organizationCity: Yup.string().required(t('fundraiser.validation.CityRequired')),
      organizationState: Yup.string().required(t('fundraiser.validation.StateRequired')),
      organizationZipCode: Yup.string().required(t('fundraiser.validation.ZipRequired')),
      federalTaxId: Yup.string()
        .max(100, t('fundraiser.validation.FederalTaxIdLength')),
      taxAcknowledgement: Yup.boolean()
        .required(t('fundraiser.TaxAcknowledgement'))
        .oneOf([ true ], t('fundraiser.TaxAcknowledgement')),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ t, earliestPossibleStartDate ]);

  const onGroupSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGroupId(e.currentTarget.value);
  };

  const calculateKitGoal = (sellers, dollarGoal) => {
    if (!sellers || !dollarGoal) {
      setCalculatedKitGoal(0);
      return;
    }

    const kits = dollarGoal / 6 / sellers;
    setCalculatedKitGoal(Math.ceil(kits));
  };

  const onDollarGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    const goal = parseInt(e.target.value, 10);
    setDollarGoal(goal);

    if (goal === 0 || numberOfSellers === 0) {
      setCalculatedKitGoal(0);
    }

    calculateKitGoal(numberOfSellers, goal);
  };

  const onNumberOfSellerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    const sellers = parseInt(e.target.value, 10);
    setNumberOfSellers(sellers);

    if (sellers === 0 || dollarGoal === 0) {
      setCalculatedKitGoal(0);
    }

    calculateKitGoal(sellers, dollarGoal);
  };

  const handleOnSubmit = (e) => {
    const { organizationGroupTypeId, ...formData } = e;
    const GroupTypeName = _.find(groupTypes, gt => gt.Id === parseInt(organizationGroupTypeId, 10))?.Name;
    const info: Partial<FundraiserBrochure> = {
      ...formData,
      organizationGroupTypeId: hasOutOfRangeGroupTypeId
        ? existingGroup?.GroupTypeId
        : parseInt(organizationGroupTypeId, 10),
      organizationAddressId: existingGroup?.GroupAddress?.Id || 0,
      organizationGroupId: existingGroup?.Id || 0,
      organizationGroupTypeName: GroupTypeName,
      sellerKitGoals: calculatedKitGoal,
      fundraiserType: FundraiserType.Brochure,
    };
    onNext(info);
  };

  if (isGroupLoading || isGroupTypeLoading) {
    return (
      <Step title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
        <Container sx={ { textAlign: 'center', pt: '30px', pb: '30px' } }>
          <Spinner variant="lce" />
        </Container>
      </Step>
    );
  }

  return (
    <>
      { mode === FundraiserFormStepState.Active && isStatesFetched && (
        <Step title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
          <Box data-testid="fundraiser-details-form" variant="cards.step.body">
            <Form
              initialValues={ { ...info, startDate: defaultStartDateFormatted } }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                <Text sx={ { mb: '6px' } } variant="text.header.form">
                  {t('fundraiser.Organization')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  {showGroupList && (
                    <Form.Select
                      defaultValue={ info?.organizationGroupId }
                      id="organizationGroupId"
                      label={ t('fundraiser.labels.GroupId') }
                      name="organizationGroupId"
                      onChange={ e => onGroupSelect(e) }
                      sx={ { my: '0px' } }
                    >
                      <Option key="" label={ t('fundraiser.labels.GroupIdDefault') } value="0" />
                      {!_.isEmpty(groups) && _.map(groups, gt => (
                        <Option key={ gt.Id } label={ gt.Name } value={ gt.Id } />
                      ))}
                    </Form.Select>
                  )}
                  <Form.Field
                    autoComplete="organization"
                    defaultValue={ existingGroup?.Name }
                    hint={ t('fundraiser.labels.OrgNameHint') }
                    id="name"
                    label={ t('fundraiser.labels.OrgName') }
                    name="name"
                    sx={ { my: '0px' } }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    component={ Form.Select }
                    defaultValue={ defaultGroupTypeId || 0 }
                    id="organizationGroupTypeId"
                    key={ defaultGroupTypeId }
                    label={ t('fundraiser.labels.GroupTypeId') }
                    name="organizationGroupTypeId"
                    onChange={ () => setGroupTypeIdDirty(true) }
                    sx={ { my: '0px' } }
                    variant="forms.fundraiser.field"
                  >
                    <Option key="" label={ t('fundraiser.labels.GroupTypeIdDefault') } value={ 0 } />
                    {groupTypes && _.map(groupTypes, gt => (
                      <Option
                        key={ gt.Id }
                        label={ gt.Name }
                        value={ gt.Id }
                      />
                    ))}
                  </Form.Field>
                  <Form.Field
                    defaultValue={ defaultStartDateFormatted }
                    id="startDate"
                    label={ t('fundraiser.labels.StartDate') }
                    min={ format(earliestPossibleStartDate, 'yyyy-MM-dd') }
                    name="startDate"
                    sx={ { my: '0px' } }
                    type="date"
                    variant="forms.fundraiser.field"
                  />
                </Flex>

                <Text sx={ { mt: '24px', mb: '6px' } } variant="text.header.form">
                  {t('fundraiser.Goal')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                    <Form.Field
                      id="profitGoal"
                      label={ t('fundraiser.labels.DollarGoal') }
                      max="1000000"
                      min="0"
                      name="profitGoal"
                      onChange={ e => onDollarGoalChange(e) }
                      sx={ { my: '0px' } }
                      type="number"
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      id="sellerCount"
                      label={ t('fundraiser.labels.EstimatedSellers') }
                      min="0"
                      name="sellerCount"
                      onChange={ e => onNumberOfSellerChange(e) }
                      sx={ { my: '0px' } }
                      type="number"
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      disabled={ true }
                      id="sellerKitGoals"
                      label={ t('fundraiser.labels.SellerKitGoal') }
                      min="0"
                      name="sellerKitGoals"
                      sx={ { my: '0px' } }
                      type="number"
                      value={ calculatedKitGoal }
                      variant="forms.fundraiser.field"
                    />
                  </Flex>
                  <Flex
                    sx={ {
                      flexDirection: [ 'column', 'row' ],
                      gap: '16px',
                      alignItems: [ '', 'end' ],
                    } }
                  >
                    <Form.Field
                      hint="We recommend 1 brochure per Seller."
                      id="brochuresRequestCount"
                      label={ t('fundraiser.labels.BrochureCount') }
                      min="0"
                      name="brochuresRequestCount"
                      sx={ { my: '0px' } }
                      type="number"
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      component={ Form.Select }
                      defaultValue={ 0 }
                      id="postersRequestCount"
                      label={ t('fundraiser.labels.PosterCount') }
                      min="0"
                      name="postersRequestCount"
                      sx={ { my: '0px' } }
                      type="number"
                      variant="forms.fundraiser.field"
                    >
                      { _.range(0, 4).map(value => (
                        <Option key={ value } label={ value.toString() } value={ value } />
                      ))}
                    </Form.Field>
                  </Flex>
                  <Form.Field
                    id="couponCode"
                    label={ t('fundraiser.labels.CouponCode') }
                    name="couponCode"
                    sx={ { input: { width: [ '100%', 'calc(50% - 8px)' ] }, my: '0px' } }
                    type="text"
                  />
                </Flex>

                <Text sx={ { mt: '24px' } } variant="text.header.form">
                  {t('fundraiser.GroupMailingAddress')}
                </Text>
                <Text sx={ { mb: '6px' } } variant="text.header.instructions">
                  {t('fundraiser.GroupMailingAddressInstructions')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                    <Form.Field
                      autoComplete="address-line1"
                      defaultValue={ existingGroup?.GroupAddress?.Address1 }
                      id="organizationStreetAddress"
                      label={ t('fundraiser.labels.GroupAddress1') }
                      name="organizationStreetAddress"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      autoComplete="address-line2"
                      defaultValue={ existingGroup?.GroupAddress?.Address2 }
                      id={ t('fundraiser.labels.GroupAddress2') }
                      label="Apt/Suite/Other (optional)"
                      name="organizationStreetAddress2"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ], gap: '16px', alignItems: [ '', 'end' ] } }>
                    <Form.Field
                      autoComplete="address-level2"
                      defaultValue={ existingGroup?.GroupAddress?.City }
                      id="organizationCity"
                      label={ t('fundraiser.labels.GroupCity') }
                      name="organizationCity"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                    <Form.Field
                      autoComplete="address-level1"
                      component={ Form.Select }
                      defaultValue={ defaultState || '' }
                      id="organizationState"
                      key={ defaultState }
                      label={ t('fundraiser.labels.GroupState') }
                      name="organizationState"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    >
                      <Option value="">---</Option>
                      {states && _.map(states, state => (
                        <Option
                          key={ state.Id }
                          label={ state.Abbreviation }
                          value={ state.Abbreviation }
                        />
                      ))}
                    </Form.Field>
                    <Form.Field
                      autoComplete="postal-code"
                      defaultValue={ existingGroup?.GroupAddress?.ZipPostalCode }
                      id="organizationZipCode"
                      label={ t('fundraiser.labels.GroupZip') }
                      name="organizationZipCode"
                      sx={ { my: '0px' } }
                      variant="forms.fundraiser.field"
                    />
                  </Flex>
                </Flex>
                { canEditSalesCode && (
                  <>
                    <Text sx={ { mt: '24px', mb: '6px' } } variant="text.header.form">
                      {t('fundraiser.SalesAgent')}
                    </Text>
                    <Box
                      sx={ {
                        display: 'grid',
                        gridTemplateColumns: [ '1fr', '1fr 1fr' ],
                        gap: '16px',
                        alignItems: [ '', 'end' ],
                      } }
                    >
                      <Flex sx={ { flexDirection: 'column' } }>
                        <Form.Field
                          id="salesAgentCode"
                          label={ t('fundraiser.labels.SalesAgentCode') }
                          maxLength={ 15 }
                          name="salesAgentCode"
                          onChange={ e => setSalesCode(e.target.value) }
                          sx={ { my: '0px' } }
                        />
                        { salesAgentHasErrors && (
                          <Box color="red">
                            { t('fundraiser.labels.SalesAgentCodeError') }
                          </Box>
                        )}
                        { isSuccessForSalesAgent && salesAgent && salesAgent.SalesCode && (
                          <Box color="secondaryGreenDark">
                            { t('fundraiser.labels.SalesAgentCodeSuccess') }
                          </Box>
                        )}
                      </Flex>
                      <Button
                        disabled={ isSalesAgentLoading }
                        onClick={ () => setCanFetchSalesAgent(true) }
                        sx={ {
                          border: '1px solid',
                          borderColor: 'primary',
                          // eslint-disable-next-line no-extra-parens
                          mb: (isSuccessForSalesAgent && salesAgent && salesAgent.SalesCode) ||
                            salesAgentHasErrors
                            ? [ '0px', '24px' ]
                            : '0px',
                        } }
                        type="button"
                        variant={ isSalesAgentLoading ? 'disabled' : 'primary' }
                      >
                        { isSalesAgentLoading ? (
                          <Spinner sx={ { height: '100%' } } variant="lce" />
                        ) : t('fundraiser.labels.SalesAgentCodeApply') }
                      </Button>
                    </Box>
                  </>
                )}
                <Text sx={ { mt: '24px', mb: '6px' } } variant="text.header.form">
                  {t('fundraiser.FundraiserTaxId')}
                </Text>
                <Flex sx={ { flexDirection: 'column', gap: '16px' } }>
                  <Form.Field
                    hint={ t('fundraiser.FederalTaxIdHelp') }
                    id="federalTaxId"
                    label={ t('fundraiser.labels.FederalTaxId') }
                    name="federalTaxId"
                    sx={ { input: { width: [ '100%', 'calc(50% - 8px)' ] }, my: '0px' } }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    component={ Form.Input.Checkbox }
                    defaultChecked={ false }
                    id="taxAcknowledgement"
                    inline={ true }
                    label={ t('fundraiser.labels.TaxAcknowledgement') }
                    name="taxAcknowledgement"
                    sx={ { my: '0px', svg: { color: 'black' } } }
                  />
                </Flex>
                <Button
                  id="fundraisingDetailsNext"
                  sx={ { mt: '24px', border: '1px solid', borderColor: 'primary' } }
                  type="submit"
                >
                  {t('fundraiser.FundraisingDetailsNext')}
                </Button>
              </Flex>
            </Form>
          </Box>
        </Step>
      )}

      { mode === FundraiserFormStepState.Preview && (
        <Step onEdit={ onEdit } showEdit={ true } title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
          <Box variant="cards.step.body">
            <FundraiserDetailsSummary details={ info } />
          </Box>
        </Step>
      )}
    </>
  );
};

export default FundraiserDetails;
