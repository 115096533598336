import { useQuery } from 'react-query';

import { fetchFundraiserOrderSummary } from '../api';
import { FundraiserOrderSummary } from '../types/fundraiser';
import * as QueryKeys from '../../../constants/QueryKeys';

import { ApiError } from 'features/common';

export const useFetchFundraiserOrderSummary =
  (id: number) => useQuery<FundraiserOrderSummary, ApiError>(`${ QueryKeys.FundraiserOrderSummary }-${ id }`,
    () => fetchFundraiserOrderSummary(id), {
      retry: false, refetchOnWindowFocus: false, cacheTime: 0, enabled: id > 0,
    });

