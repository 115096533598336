/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Heading, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import FundraiserDetails from './Form/FundraiserDetails';
import ChairpersonDetails from './Form/ChairpersonDetails';
import DeliveryDetails from './Form/DeliveryDetails';
import AdditionalDetails from './Form/AdditionalDetails';

import { useLocalStorage, useUserInfo } from 'features/common';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import CaesarCash from 'assets/images/caesar-cash.svg';
import { FundraiserCreate } from 'ui/fundraiser/FundraiserCreation/FundraiserCreate';
import { BrochureFormSteps, FundraiserBrochure, FundraiserFormStepState } from 'features/fundraiser/types/fundraiser';
import { useSessionStorage } from 'features/common/hooks/useSessionStorage';
import { useCreateFundraiserBrochure } from 'features/fundraiser/hooks/useCreateFundraiserBrochure';

export type BrochureCreatePageProps = {
  testId?: string;
}

export const dataTestIdBrochureCreatePage = 'brochure-create-component';

type StepData = {
  info: Partial<FundraiserBrochure>;
  stepFilled: BrochureFormSteps;
}

export const BrochureCreatePage: React.FC<BrochureCreatePageProps> = ({
  testId = dataTestIdBrochureCreatePage,
}) => {
  const history = useHistory();
  const [ t ] = useTranslation();
  const { authState } = useOktaAuth();
  const { mutateAsync: createFundraiser, isLoading, isError } = useCreateFundraiserBrochure();
  const [ formData, setFormData, removeFormData ] = useSessionStorage(FundraiserDefaults.CreateFundraiserSessionId, {} as FundraiserBrochure);

  const { fundraiserType } = useFundraiserState();

  const hasParentGroupId = false;

  const [ referralSource, setReferralSource ] = useLocalStorage<string>('referralSource');
  const [ franchiseeId, setFranchiseeId ] = useLocalStorage<string>('franchiseeId');
  const isFranchiseeReferral = Boolean(franchiseeId);
  const hasReferralSource = Boolean(referralSource);
  const showHowDidYouHearAboutUs = !hasParentGroupId && !isFranchiseeReferral && !hasReferralSource;

  const { data: userInfo } = useUserInfo(authState.isAuthenticated);

  const [ currentStep, setCurrentStep ] = useState<BrochureFormSteps>(BrochureFormSteps.FundraiserDetails);
  const allStepsCompleted = formData?.fundraiserDetailsFilled && formData?.chairpersonDetailsFilled && formData?.deliveryDetailsFilled && formData?.additionalDetailsFilled;

  const getStepMode = (stepName: BrochureFormSteps) => {
    switch (stepName) {
      case currentStep:
        return FundraiserFormStepState.Active;
      case BrochureFormSteps.FundraiserDetails:
        return formData.fundraiserDetailsFilled ? FundraiserFormStepState.Preview : FundraiserFormStepState.Hidden;
      case BrochureFormSteps.ChairpersonDetails:
        return formData.chairpersonDetailsFilled ? FundraiserFormStepState.Preview : FundraiserFormStepState.Hidden;
      case BrochureFormSteps.DeliveryDetails:
        return formData.deliveryDetailsFilled ? FundraiserFormStepState.Preview : FundraiserFormStepState.Hidden;
      case BrochureFormSteps.AdditionalDetails:
        return formData.additionalDetailsFilled ? FundraiserFormStepState.Preview : FundraiserFormStepState.Hidden;
      default:
        return FundraiserFormStepState.Hidden;
    }
  };

  const updateCurrentStep = useCallback((formData: FundraiserBrochure) => {
    const stepsConditions = [
      { condition: !formData.fundraiserDetailsFilled, step: BrochureFormSteps.FundraiserDetails },
      { condition: !formData.chairpersonDetailsFilled, step: BrochureFormSteps.ChairpersonDetails },
      { condition: !formData.deliveryDetailsFilled, step: BrochureFormSteps.DeliveryDetails },
      { condition: !formData.additionalDetailsFilled, step: BrochureFormSteps.AdditionalDetails },
    ];

    if (allStepsCompleted) {
      setCurrentStep(BrochureFormSteps.AdditionalDetails);
      return;
    }

    for (const { condition, step } of stepsConditions) {
      if (condition) {
        setCurrentStep(step);
        break;
      }
    }
  }, [ allStepsCompleted ]);

  useEffect(() => {
    updateCurrentStep(formData);
  }, [ formData, updateCurrentStep ]);

  const goToNextStep = () => {
    const currentIndex = Object.values(BrochureFormSteps).indexOf(currentStep);
    const nextStep = Object.values(BrochureFormSteps)[(currentIndex + 1) % Object.values(BrochureFormSteps).length];
    setCurrentStep(nextStep as BrochureFormSteps);
  };

  const saveData = ({
    info, stepFilled,
  }: StepData) => {
    const data = { ...info };

    if (info.sameAsMailing) {
      data.deliveryAddress = info.organizationStreetAddress;
      data.deliveryCity = info.organizationCity;
      data.deliveryState = info.organizationState;
      data.deliveryZipCode = info.organizationZipCode;
    }

    setFormData({
      ...formData, ...data, [`${ stepFilled }Filled`]: true,
    });
  };

  const handleCreateFundraiser = async() => {
    const isLCEStore = isFranchiseeReferral || formData?.Referral === t('HowDidYouHearAboutUsOptions.LCEStore');

    let referral = '';

    if (hasReferralSource) {
      referral = referralSource;
    } else if (isLCEStore) {
      referral = franchiseeId;
    } else {
      referral = formData?.Referral || '';
    }

    if (formData && allStepsCompleted) {
      const fundraiser: FundraiserBrochure = {
        ...formData,
        Id: 0,
        Referral: referral,
      };

      const fundraiserData = await createFundraiser(fundraiser);
      if (fundraiserData) {
        setFranchiseeId(undefined);
        setReferralSource(undefined);
        removeFormData();
        history.push('/brochure/start-a-fundraiser/confirm');
      }
    } else {
      updateCurrentStep(formData);
    }
  };

  useEffect(() => {
    if (allStepsCompleted) {
      handleCreateFundraiser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ allStepsCompleted ]);

  return (
    <FundraiserCreate image={ CaesarCash } testId={ testId }>
      <Box sx={ { flex: 1, mb: '24px' } }>
        <Heading>
          {t('fundraiser.StartAFundraiser', { context: fundraiserType })}
        </Heading>
        <Text>
          {t('fundraiser.labels.RequiredFieldIndicator')}
        </Text>

        <FundraiserDetails
          fundraiserType={ fundraiserType }
          info={ formData }
          mode={ getStepMode(BrochureFormSteps.FundraiserDetails) }
          onEdit={ () => setCurrentStep(BrochureFormSteps.FundraiserDetails) }
          onNext={ (info) => {
            saveData({ info, stepFilled: BrochureFormSteps.FundraiserDetails });
            goToNextStep();
          } }
        />
        <ChairpersonDetails
          defaultChairperson={ !userInfo?.Roles.includes(FundraiserDefaults.SuperAdminRoleName) ? userInfo : undefined }
          fundraiserType={ fundraiserType }
          info={ formData }
          mode={ getStepMode(BrochureFormSteps.ChairpersonDetails) }
          onEdit={ () => setCurrentStep(BrochureFormSteps.ChairpersonDetails) }
          onNext={ (info) => {
            saveData({ info, stepFilled: BrochureFormSteps.ChairpersonDetails });
            goToNextStep();
          } }
        />
        <DeliveryDetails
          fundraiserType={ fundraiserType }
          info={ formData }
          mode={ getStepMode(BrochureFormSteps.DeliveryDetails) }
          onEdit={ () => setCurrentStep(BrochureFormSteps.DeliveryDetails) }
          onNext={ (info) => {
            saveData({ info, stepFilled: BrochureFormSteps.DeliveryDetails });
            goToNextStep();
          } }
        />
        <AdditionalDetails
          fundraiserType={ fundraiserType }
          info={ formData }
          isError={ isError }
          isLoading={ isLoading }
          mode={ getStepMode(BrochureFormSteps.AdditionalDetails) }
          onNext={ (info) => {
            saveData({ info, stepFilled: BrochureFormSteps.AdditionalDetails });
          } }
          showHowDidYouHearAboutUs={ showHowDidYouHearAboutUs }
        />
      </Box>
    </FundraiserCreate>
  );
};
