import React, { useCallback, useState } from 'react';
import {
  Form, Flex, Spinner, Text, Input,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';

import { Step, GenericToast } from 'ui/common';
import { useFetchFundraiserOrderSummary, useCreateFinalOrder } from 'features/fundraiser/hooks';
import { FundraiserListItem } from 'features/fundraiser/types';
import { SubmitButton } from 'ui/components';
import { useSafeDateFormat } from 'ui/hooks';

import './final-order-form.css';

export type FinalOrderFormProps = {
  fundraiser: FundraiserListItem;
  testId?: string;
}

export const dataTestIdFinalOrderForm = 'final-order-form-component';

export type ManualOrderTotal= {
  productsTotal: Record<number, number>;
  total: number;
}

// eslint-disable-next-line max-lines-per-function
export const FinalOrderForm: React.FC<FinalOrderFormProps> = ({
  fundraiser,
  testId = dataTestIdFinalOrderForm,
}) => {
  const [ brochureOrderCount, setBrochureOrderCount ] = useState<ManualOrderTotal>({ productsTotal: {}, total: 0 });
  const [ t ] = useTranslation();
  const {
    data: orderSummary,
    isLoading, isError: isErrorFetchingOrderSummary,
  } = useFetchFundraiserOrderSummary(fundraiser.Id);
  const { mutateAsync: createFinalOrderMutation } = useCreateFinalOrder();

  const deliveryEndDate = useSafeDateFormat(orderSummary?.deliveryEndDate);
  const deliveryStartDate = useSafeDateFormat(orderSummary?.deliveryStartDate);

  const onSubmit = useCallback(async() => {
    const data = {
      fundraiserId: fundraiser.Id,
      kitOrders: orderSummary?.kitOrders.map(kitOrder => ({
        ...kitOrder,
        quantity: brochureOrderCount.productsTotal[kitOrder.id],
      })) ?? [],
    };

    try {
      await createFinalOrderMutation(data);
      toast(<GenericToast text={ t('dashboard.finalOrderPage.form.toast.success') } />);
    } catch (error) {
      toast.error(t('dashboard.finalOrderPage.form.toast.error'));
    }
  }, [ orderSummary, brochureOrderCount, fundraiser, createFinalOrderMutation, t ]);

  const onProductQuantityChange = useCallback((e) => {
    const { name, value } = e.target;
    const numericValue = value === '' ? '0' : value;
    brochureOrderCount.productsTotal[name] = parseInt(numericValue, 10);
    brochureOrderCount.total = Object.values(brochureOrderCount.productsTotal).reduce((acc, val) => acc + val, 0);
    setBrochureOrderCount({ ...brochureOrderCount });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isErrorFetchingOrderSummary) {
    toast.error(t('dashboard.finalOrderPage.form.toast.fetchError'));
    return null;
  }

  if (isLoading) {
    return (
      <Flex
        data-testid={ testId }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner
          variant="lce"
        />
      </Flex>
    );
  }

  return (
    <Flex data-testid={ testId } sx={ { gap: '12px', flexDirection: 'column' } }>
      <Text sx={ { fontWeight: 'bold' } }>
        { t('dashboard.finalOrderPage.deliveryRange', { deliveryEndDate, deliveryStartDate }) }
      </Text>
      <Step title={ t('dashboard.finalOrderPage.form.header') }>
        <Form onSubmit={ onSubmit }>
          <table className="final-order-table">
            <thead className="final-order-table__header">
              <tr>
                <th />
                <th>
                  {t('dashboard.finalOrderPage.form.headings.product')}
                </th>
                <th>
                  {t('dashboard.finalOrderPage.form.headings.onlineQuantity')}
                </th>
                <th>
                  {t('dashboard.finalOrderPage.form.headings.brochureQuantity')}
                </th>
              </tr>
              <tr>
                <th className="final-order-table__header-cell" colSpan={ 4 } />
              </tr>
            </thead>
            <tbody>
              { orderSummary?.kitOrders.map(({
                id, code, color, price, name, quantity,
              }) => (
                <tr key={ id }>
                  <td>
                    <Flex
                      sx={ {
                        backgroundColor: color,
                        color: 'secondary',
                        display: 'inline-flex',
                        height: '30px',
                        width: '30px',
                        borderRadius: '5px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 5px 0 0',
                      } }
                    >
                      <Text>
                        {code}
                      </Text>
                    </Flex>
                  </td>
                  <td>
                    {name}
                    {' '}
                    (
                    {code}
                    ): $
                    {price}
                  </td>
                  <td>
                    {quantity}
                  </td>
                  <td>
                    <Input
                      defaultValue={ 0 } min={ 0 } name={ id.toString() } onChange={ onProductQuantityChange }
                      type="number"
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td className="final-order-table__footer-cell" colSpan={ 4 } />
              </tr>
              <tr>
                <td />
                <td>
                  <Text sx={ { fontWeight: 'bold' } }>
                    {t('dashboard.finalOrderPage.form.total')}
                  </Text>
                </td>
                <td>
                  <Text sx={ { fontWeight: 'bold' } }>
                    {orderSummary?.kitTotal}
                  </Text>
                </td>
                <td>
                  <Text data-testid={ `${ dataTestIdFinalOrderForm }-total` } sx={ { fontWeight: 'bold' } }>
                    {brochureOrderCount.total}
                  </Text>
                </td>
              </tr>
              <tr>
                <td colSpan={ 4 }>
                  <SubmitButton
                    data-testid={ `${ dataTestIdFinalOrderForm }-submit-btn` }
                    sx={ { width: '100%' } } variant="primary.large"
                  >
                    {t('dashboard.finalOrderPage.form.submitButton')}
                  </SubmitButton>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </Step>
    </Flex>
  );
};
